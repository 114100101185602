var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.isLoading || _vm.isSaving
        ? _c("b-overlay", { attrs: { show: _vm.isLoading || _vm.isSaving } }, [
            _c(
              "div",
              { staticClass: "w-100 loading-placeholder rounded bg-light" },
              [_c("b-skeleton")],
              1
            ),
          ])
        : [
            _vm.canTransfer
              ? _c(
                  "div",
                  { staticClass: "w-100 d-flex mb-3" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-auto",
                        attrs: { variant: "outline-primary" },
                        on: {
                          click: function ($event) {
                            _vm.isTransferMode = !_vm.isTransferMode
                          },
                        },
                      },
                      [
                        !_vm.isTransferMode
                          ? [
                              _c("b-icon-arrow-left-right"),
                              _vm._v(" Transfer Keywords"),
                            ]
                          : [_c("b-icon-chevron-left"), _vm._v(" Go back")],
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isTransferMode
              ? [
                  !_vm.isLoading
                    ? _c(
                        "config-create",
                        {
                          staticClass: "w-100",
                          attrs: { "ca-config": _vm.caConfig },
                        },
                        [
                          _vm.$store.state.caConfig.lastCompareGroupId === 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 bg-light p-3 rounded-lg mb-3",
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "Keyword Spotting lets you tell Conversation Analytics the words and phrases you want to look for on your calls."
                                    ),
                                  ]),
                                  _c("p", { staticClass: "text-whale mb-0" }, [
                                    _vm._v(
                                      "Let's start by creating your first group."
                                    ),
                                  ]),
                                ]
                              )
                            : !_vm.keywordSpottingOnly
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 bg-light p-3 rounded-lg mb-3",
                                },
                                [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      "Continue adding groups and phrases until you're ready to continue."
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "w-100 mt-3 d-flex" },
                    [
                      _c(
                        "b-overlay",
                        {
                          staticClass: "ml-auto",
                          attrs: { show: _vm.isSaving },
                        },
                        [
                          _vm.$store.state.caConfig.lastCompareKeywordId > 0
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    disabled: !_vm.canSave,
                                    variant: "success",
                                  },
                                  on: { click: _vm.doSave },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.keywordSpottingOnly
                                          ? "Save"
                                          : "Continue"
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _c("keyword-transfer", {
                  attrs: { "ca-config": _vm.$store.state.caConfig.baseConfig },
                  on: { complete: _vm.onTransferComplete },
                }),
          ],
      _c("b-toaster", { attrs: { name: "b-toaster-top-right" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }