var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c("b-col", { staticClass: "mt-5", attrs: { cols: "6" } }, [
            _c(
              "div",
              { staticClass: "w-100 bg-white rounded-lg border shadow-lg p-3" },
              [
                _c("h4", [_vm._v("Setup Complete")]),
                _c("p", [
                  _vm._v(
                    "You've now set up what you need for Conversation Analytics to start gathering insight on your calls."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Check if Conversation Analytics has already started work on your calls, or use our test engine to see how your settings work against existing ones."
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-2 d-flex" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "success" },
                        on: { click: _vm.doClickTester },
                      },
                      [_vm._v("Test your settings")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-auto",
                        attrs: { variant: "outline-primary" },
                        on: { click: _vm.doClickLog },
                      },
                      [_vm._v("Go to Call Log")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }