var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("b-table", {
        directives: [
          {
            name: "test",
            rawName: "v-test",
            value: "group table",
            expression: "'group table'",
          },
        ],
        ref: "keywordGroupTable",
        staticClass: "rounded-lg bg-white shadow-sm border",
        attrs: {
          items: _vm.tableConfig.items,
          fields: _vm.tableConfig.fields,
          "selected-variant": "",
          "no-select-on-click": "",
          selectable: "",
        },
        on: { "row-clicked": _vm.onGroupSelected },
        scopedSlots: _vm._u([
          {
            key: "cell(selected)",
            fn: function (row) {
              return [
                _c("b-form-checkbox", {
                  directives: [
                    {
                      name: "test",
                      rawName: "v-test",
                      value: `select group ${row.item.keywordGroupId}`,
                      expression: "`select group ${row.item.keywordGroupId}`",
                    },
                  ],
                  attrs: {
                    disabled: "",
                    indeterminate: _vm.isGroupIndeterminate(
                      row.item.keywordGroupId
                    ),
                    checked: row.rowSelected,
                    size: "lg",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(showKeywords)",
            fn: function (row) {
              return [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle",
                        value: `keywords-${row.item.keywordGroupId}`,
                        expression: "`keywords-${row.item.keywordGroupId}`",
                      },
                      {
                        name: "test",
                        rawName: "v-test",
                        value: `show keywords ${row.item.keywordGroupId}`,
                        expression:
                          "`show keywords ${row.item.keywordGroupId}`",
                      },
                    ],
                    staticClass: "float-right",
                    attrs: {
                      variant: "outline-secondary",
                      size: "sm",
                      pill: "",
                    },
                  },
                  [_vm._v(" Show Keywords "), _c("b-icon-chevron-down")],
                  1
                ),
              ]
            },
          },
          {
            key: "row-details",
            fn: function (row) {
              return [
                _c(
                  "b-collapse",
                  { attrs: { id: `keywords-${row.item.keywordGroupId}` } },
                  [
                    _c("b-table", {
                      directives: [
                        {
                          name: "test",
                          rawName: "v-test",
                          value: `keyword table ${row.item.keywordGroupId}`,
                          expression:
                            "`keyword table ${row.item.keywordGroupId}`",
                        },
                      ],
                      ref: `keywordTable${row.item.keywordGroupId}`,
                      attrs: {
                        items: row.item.keywordsTableConfig.items,
                        fields: row.item.keywordsTableConfig.fields,
                        "selected-variant": "",
                        "no-select-on-click": "",
                        selectable: "",
                      },
                      on: { "row-clicked": _vm.onKeywordSelected },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(selected)",
                            fn: function (keywordRow) {
                              return [
                                _c("b-form-checkbox", {
                                  directives: [
                                    {
                                      name: "test",
                                      rawName: "v-test",
                                      value: `select keyword ${keywordRow.item.keywordId}`,
                                      expression:
                                        "`select keyword ${keywordRow.item.keywordId}`",
                                    },
                                  ],
                                  attrs: {
                                    disabled: "",
                                    checked: keywordRow.rowSelected,
                                    size: "lg",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "w-100 rounded-lg border shadow-sm mt-3 p-3 bg-white" },
        [
          _c("h5", [_vm._v("Select Installation")]),
          _c("b-form-input", {
            directives: [
              {
                name: "test",
                rawName: "v-test",
                value: "installation search",
                expression: "'installation search'",
              },
            ],
            attrs: {
              placeholder: "Type to search for an Installation",
              debounce: "500",
              trim: "",
            },
            on: { update: _vm.doSearchInstallations },
            model: {
              value: _vm.installationQuery,
              callback: function ($$v) {
                _vm.installationQuery = $$v
              },
              expression: "installationQuery",
            },
          }),
          _c(
            "b-overlay",
            { staticClass: "mt-3", attrs: { show: _vm.isLoading } },
            [
              _c(
                "b-list-group",
                [
                  _vm.installations.length === 0
                    ? _c(
                        "b-list-group-item",
                        {
                          directives: [
                            {
                              name: "test",
                              rawName: "v-test",
                              value: "installations empty",
                              expression: "'installations empty'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "Use the search above to find the Installation you want to transfer to."
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.installations, function (installation) {
                    return _c(
                      "b-list-group-item",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: `installation ${installation.getId()}`,
                            expression:
                              "`installation ${installation.getId()}`",
                          },
                        ],
                        key: installation.getId(),
                        attrs: {
                          active: _vm.selectedInstallation === installation,
                          button: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.selectedInstallation = installation
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(installation.getName()) + " ")]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-100 mt-3 d-flex" },
        [
          _c(
            "b-overlay",
            { staticClass: "ml-auto", attrs: { show: _vm.isSaving } },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "test",
                      rawName: "v-test",
                      value: "transfer button",
                      expression: "'transfer button'",
                    },
                  ],
                  attrs: { disabled: !_vm.canSave, variant: "success" },
                  on: { click: _vm.doSave },
                },
                [_vm._v(" Transfer ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }