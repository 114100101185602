var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "b-container",
        [
          _vm.$route.path !== "/"
            ? _c(
                "b-row",
                [
                  _vm.$route.path !== "/complete"
                    ? _c("h3", { staticClass: "my-3" }, [
                        _vm._v("Setup Conversation Analytics"),
                      ])
                    : _vm._e(),
                  _c("b-tabs", {
                    staticClass: "w-100 mb-3 wizard-tabs",
                    attrs: { fill: "", justified: "", pills: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tabs-start",
                          fn: function () {
                            return [
                              _c(
                                "b-nav-item",
                                { attrs: { to: "/keyword-spotting" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "wizard-tab" },
                                    [
                                      _c("b-avatar", {
                                        staticClass: "mr-2",
                                        attrs: { text: "1" },
                                      }),
                                      _vm._v(" Keyword Spotting "),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    to: "/sensitive-data",
                                    disabled:
                                      !_vm.$store.state
                                        .hasCompletedKeywordConfig,
                                    href: "",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "wizard-tab" },
                                    [
                                      _c("b-avatar", {
                                        staticClass: "mr-2",
                                        attrs: { text: "2" },
                                      }),
                                      _vm._v(" Sensitive Data "),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3991396659
                    ),
                  }),
                  _c("router-view"),
                ],
                1
              )
            : _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c("b-col", { staticClass: "mt-5", attrs: { cols: "6" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 bg-white rounded-lg border shadow-lg p-3",
                      },
                      [
                        _c("h4", [_vm._v("Welcome to Conversation Analytics")]),
                        _c("p", [
                          _vm._v(
                            "You're about to go through the process of setting up Conversation Analytics on this Installation."
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "Here, we'll guide you in creating the basics to get you started on your journey - letting us know the words, phrases and security settings that are important to you."
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "When you're ready, click start to get going."
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-2 d-flex" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "ml-auto",
                                attrs: {
                                  disabled: !_vm.hasBooted,
                                  variant: "outline-primary",
                                },
                                on: { click: _vm.startWizard },
                              },
                              [_vm._v("Start")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }