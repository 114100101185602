var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.isLoading
        ? _c("b-overlay", { attrs: { show: _vm.isLoading } }, [
            _c(
              "div",
              { staticClass: "w-100 loading-placeholder rounded bg-light" },
              [_c("b-skeleton")],
              1
            ),
          ])
        : _c("b-overlay", { attrs: { show: _vm.isSaving } }, [
            !_vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "w-100 bg-white p-3 border rounded" },
                  [
                    _c("h5", [_vm._v("Sensitive Data")]),
                    _c(
                      "div",
                      { staticClass: "w-100 bg-light p-3 rounded-lg" },
                      [
                        _c("p", [
                          _vm._v(
                            "If you handle data, such as personal information or payment details, on your calls, you should enable redaction to prevent customer data from being exposed."
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "When enabled, Conversation Analytics will check calls for the types of data you want to redact, and remove them from recordings and transcriptions."
                          ),
                        ]),
                        _c("p", { staticClass: "text-whale mb-0" }, [
                          _vm._v(
                            "The presence of redacted data on a call can be used to trigger goals."
                          ),
                        ]),
                      ]
                    ),
                    _vm.hasExistingRedaction && !_vm.showRedactionOptions
                      ? [
                          _c("h5", { staticClass: "mt-3 text-center" }, [
                            _vm._v(
                              "We found some existing redaction settings on your Tracking Pools."
                            ),
                          ]),
                          _c("p", { staticClass: "text-center" }, [
                            _vm._v(
                              "Do you want to keep what you have, or choose something else?"
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-center mt-3",
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mx-2",
                                  attrs: { variant: "outline-primary" },
                                  on: { click: _vm.keepSettings },
                                },
                                [_vm._v("Keep my current settings")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mx-2",
                                  attrs: { variant: "success" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showRedactionOptions = true
                                    },
                                  },
                                },
                                [_vm._v("Choose something else")]
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c("p", { staticClass: "my-3" }, [
                            _vm._v(
                              "Please choose the redaction options you need:"
                            ),
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "redaction-options" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "redaction-option",
                                      attrs: {
                                        variant: _vm.isPciSelected
                                          ? "primary"
                                          : "outline-whale",
                                      },
                                      on: { click: _vm.doSelectPci },
                                    },
                                    [
                                      _c("h5", [_vm._v("PCI Redaction")]),
                                      _c("span", [
                                        _vm._v(
                                          "Detect and remove payment information from your calls"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "redaction-option",
                                      attrs: {
                                        variant: _vm.isSsnSelected
                                          ? "primary"
                                          : "outline-whale",
                                      },
                                      on: { click: _vm.doSelectSsn },
                                    },
                                    [
                                      _c("h5", [_vm._v("SSN Redaction")]),
                                      _c("span", [
                                        _vm._v(
                                          "Detect and remove social security numbers from your calls"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "redaction-option",
                                      attrs: {
                                        variant: _vm.isPinSelected
                                          ? "primary"
                                          : "outline-whale",
                                      },
                                      on: { click: _vm.doSelectPin },
                                    },
                                    [
                                      _c("h5", [_vm._v("PIN Redaction")]),
                                      _c("span", [
                                        _vm._v(
                                          "Detect and remove identifiable numbers from your calls"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "redaction-option",
                                      attrs: {
                                        variant: _vm.isNoneSelected
                                          ? "primary"
                                          : "outline-whale",
                                      },
                                      on: { click: _vm.doSelectNone },
                                    },
                                    [
                                      _c("h5", [_vm._v("No Redaction")]),
                                      _c("span", [
                                        _vm._v(
                                          "You don't handle sensitive data on your calls"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isOptionSelected && !_vm.isNoneSelected
                            ? [
                                _c("h5", { staticClass: "mt-3" }, [
                                  _vm._v("Tracking Pool options"),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "By default, we'll apply these settings to all of your Tracking Pools. If you prefer, you can select which tracking pools you want to use."
                                  ),
                                ]),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: { switch: "" },
                                    model: {
                                      value: _vm.showTrackingPools,
                                      callback: function ($$v) {
                                        _vm.showTrackingPools = $$v
                                      },
                                      expression: "showTrackingPools",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "I want to select my own Tracking Pools"
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _c(
                            "b-collapse",
                            {
                              staticClass: "mt-3",
                              attrs: { visible: _vm.showTrackingPools },
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-list-group",
                                        [
                                          _c(
                                            "b-list-group-item",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  debounce: "300",
                                                  placeholder:
                                                    "Find Tracking Pool by name",
                                                },
                                                on: {
                                                  update:
                                                    _vm.fetchTrackingPools,
                                                },
                                                model: {
                                                  value: _vm.trackingPoolQuery,
                                                  callback: function ($$v) {
                                                    _vm.trackingPoolQuery = $$v
                                                  },
                                                  expression:
                                                    "trackingPoolQuery",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-overlay",
                                            {
                                              attrs: {
                                                show: _vm.isFetchingTrackingPools,
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.trackingPools,
                                                function (trackingPool) {
                                                  return _c(
                                                    "b-list-group-item",
                                                    {
                                                      key: trackingPool.getId(),
                                                      attrs: { button: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectTrackingPool(
                                                            trackingPool
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            trackingPool.getName()
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm.trackingPools.length ===
                                              _vm.trackingPoolLimit
                                                ? _c(
                                                    "b-list-group-item",
                                                    [
                                                      _c(
                                                        "b-icon-info-circle-fill"
                                                      ),
                                                      _vm._v(
                                                        " We will only show the first " +
                                                          _vm._s(
                                                            _vm.trackingPoolLimit
                                                          ) +
                                                          " Tracking Pools. "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.trackingPools.length === 0
                                                ? _c("b-list-group-item", [
                                                    _vm._v(
                                                      " We couldn't find any more tracking pools. "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-list-group",
                                        [
                                          _c("b-list-group-item", [
                                            _c("h5", { staticClass: "my-1" }, [
                                              _vm._v("Selected Tracking Pools"),
                                            ]),
                                          ]),
                                          _vm._l(
                                            _vm.selectedTrackingPools,
                                            function (trackingPool) {
                                              return _c(
                                                "b-list-group-item",
                                                {
                                                  key: trackingPool.getId(),
                                                  attrs: { button: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeTrackingPool(
                                                        trackingPool
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        trackingPool.getName()
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm.selectedTrackingPools.length === 0
                                            ? _c("b-list-group-item", [
                                                _vm._v(" All Tracking Pools "),
                                              ])
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ]),
      _c(
        "div",
        { staticClass: "w-100 mt-3 d-flex" },
        [
          _c(
            "b-overlay",
            { staticClass: "ml-auto", attrs: { show: _vm.isSaving } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isOptionSelected,
                    variant: "success",
                  },
                  on: { click: _vm.doSave },
                },
                [_vm._v(" Continue ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }